package info.rezident.app.pages

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.forms.ButtonSize
import com.varabyte.kobweb.silk.components.forms.Input
import com.varabyte.kobweb.silk.components.forms.InputSize
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import dev.gitlive.firebase.Firebase
import dev.gitlive.firebase.analytics.analytics
import dev.gitlive.firebase.firestore.firestore
import info.rezident.app.components.core.isValidEmail
import info.rezident.app.components.core.loadAuthScript
import info.rezident.app.components.data.*
import info.rezident.app.components.data.auth.AuthRepository
import info.rezident.app.components.data.auth.WEB_CLIENT_ID
import info.rezident.app.components.data.auth.googleSignIn
import info.rezident.app.components.data.user.ABTestRepository
import info.rezident.app.components.sections.NavHeader
import info.rezident.app.toSitePalette
import kotlinx.browser.document
import kotlinx.coroutines.flow.firstOrNull
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H1
import org.jetbrains.compose.web.dom.H3
import org.jetbrains.compose.web.dom.Text
import kotlin.js.Date

private const val GOOGLE_SIGNIN_BTN_ID = "google-signin-button"

@Page
@Composable
fun HelloPage() {
    LaunchedEffect(Unit) {
        document.title = "Hai să ne cunoaștem 😊"
    }

    Column(Modifier.fillMaxSize()) {
        val user by AuthRepository.firebaseUser.collectAsState(null)
        val scope = rememberCoroutineScope()

        NavHeader(
            isAuthenticated = user != null,
            name = user?.displayName?.takeWhile { !it.isWhitespace() },
            imageUrl = user?.photoURL,
            onSignOut = {
                scope.launch {
                    AuthRepository.logOut()
                }
            }
        )

        Column(Modifier.fillMaxSize().padding(left = 16.px, top = 24.px, right = 16.px)) {
            EmailCollectionStep()
        }
    }
}

@Composable
private fun QuizScaffold(quizStep: QuizStep) {

}

@Composable
private fun AuthStep() {
    var googleIdToken: String? by remember { mutableStateOf(null) }
    LaunchedEffect(googleIdToken) {
        googleIdToken?.let {
            AuthRepository.signInWithGoogle(it)
        }
    }

    LaunchedEffect(Unit) {
        if (AuthRepository.firebaseUser.firstOrNull() == null) {
            loadAuthScript("https://accounts.google.com/gsi/client") {
                googleSignIn(GOOGLE_SIGNIN_BTN_ID, WEB_CLIENT_ID) { idToken ->
                    googleIdToken = idToken
                }
            }
        }
    }



    GoogleSignInButton()
}

@Composable
private fun GoogleSignInButton() {
    Div(
        attrs = {
            // Id of the button. Functionality and UI are controlled from JS
            id(GOOGLE_SIGNIN_BTN_ID)
        }
    )
}

@Composable
private fun SignOutButton() {
    val scope = rememberCoroutineScope()
    Button(onClick = {
        scope.launch {
            AuthRepository.logOut()
        }
    }) {
        Text("Sign out")
    }
}

@Composable
private fun EmailCollectionStep() {
    val homeVariant = ABTestRepository.homeVariant
    val ctaVariant = ABTestRepository.ctaVariant
    val titleVariant = ABTestRepository.helloTitleVariant
    val subtitleVariant = ABTestRepository.helloSubtitleVariant
    var emailAddress by remember { mutableStateOf("") }
    var emailSent by remember { mutableStateOf(false) }

    LaunchedEffect(emailSent) {
        if (emailSent) {
            val firestore = Firebase.firestore
            val date = Date()
            firestore.collection("emails").add(mapOf("e-mail" to emailAddress, "date" to date.toString()))
            emailSent = true

            val analytics = Firebase.analytics
            analytics.logEvent(
                "email_submit",
                mapOf(
                    HomePageVariant.KEY to homeVariant.name,
                    CTAVariant.KEY to ctaVariant.name,
                    HelloPageTitleVariant.KEY to titleVariant.name,
                    HelloPageSubtitleVariant.KEY to subtitleVariant.name
                )
            )
        }
    }

    if (emailSent) {
        H1 {
            Text(
                listOf("🤝 Ne pare bine de cunoștință!", "✅ Îți mulțumim pentru interes!").random()
            )
            Div()
            Text("Revenim către tine în cel mai scurt timp.")
        }
    } else {
        SpanText(
            "Salut!👋",
            Modifier
                .lineHeight(2.75.cssRem)
                .fontSize(2.cssRem)
                .textAlign(TextAlign.Start)
        )
        SpanText(
            titleVariant.text,
            Modifier
                .lineHeight(2.75.cssRem)
                .fontSize(2.cssRem)
                .textAlign(TextAlign.Start)
        )

        SpanText(
            subtitleVariant.text,
            Modifier
                .color(
                    when (ColorMode.current) {
                        ColorMode.LIGHT -> Colors.Black
                        ColorMode.DARK -> Colors.White.darkened(0.2f)
                    }
                )
        )

        Div(Modifier.height(2.cssRem).toAttrs())

        Input(
            InputType.Email,
            emailAddress,
            onValueChange = {
                emailAddress = it
            },
            placeholder = "Adresa ta de e-mail",
            size = InputSize.LG,
        )

        Div(Modifier.height(16.px).toAttrs())

        val isValidEmail = emailAddress.isValidEmail()
        val sitePalette = ColorMode.current.toSitePalette()
        Button(
            onClick = {
                emailSent = true
            },
            modifier = Modifier
                .borderRadius(24.px)
                .styleModifier {
                    backgroundColor(
                        if (isValidEmail) {
                            sitePalette.brand.accent
                        } else {
                            Colors.Gray
                        }
                    )

                    color(
                        if (isValidEmail) {
                            Colors.Black
                        } else {
                            Colors.White
                        }
                    )
                },
            enabled = isValidEmail,
            size = ButtonSize.MD,
        ) {
            Text("Salvează")
        }
    }
}

@Composable
private fun FirestoreStep() {
    val helloCollection = Firebase.firestore.collection("hello").snapshots
    var helloText by remember { mutableStateOf("") }
    LaunchedEffect(Unit) {
        helloCollection
            .onEach { snapshot ->
                helloText = snapshot.documents.firstOrNull()?.get("text") ?: ""
            }
            .launchIn(this)
    }

    H3 {
        Text(helloText)
    }
}