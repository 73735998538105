package info.rezident.app.components.data.user

import info.rezident.app.components.core.LocalStorage
import info.rezident.app.components.data.CTAVariant
import info.rezident.app.components.data.HelloPageSubtitleVariant
import info.rezident.app.components.data.HelloPageTitleVariant
import info.rezident.app.components.data.HomePageVariant
import kotlin.enums.enumEntries

object ABTestRepository {
    val homeVariant = getOrCreateVariant<HomePageVariant>(HomePageVariant.KEY)
    val ctaVariant = getOrCreateVariant<CTAVariant>(CTAVariant.KEY)
    val helloTitleVariant = getOrCreateVariant<HelloPageTitleVariant>(HelloPageTitleVariant.KEY)
    val helloSubtitleVariant = getOrCreateVariant<HelloPageSubtitleVariant>(HelloPageSubtitleVariant.KEY)

    init {
        console.info("HomePageVariant: $homeVariant, ctaVariant: $ctaVariant, helloTitleVariant: $helloTitleVariant, helloSubtitleVariant: $helloSubtitleVariant")
    }
}

/**
 * Get the variant with [key] from [LocalStorage], otherwise generate and save a variant to [LocalStorage].
 */
private inline fun <reified T : Enum<T>> getOrCreateVariant(key: String): T =
    LocalStorage.get(key)?.let { variant ->
        enumEntries<T>().firstOrNull { entry -> entry.name == variant }
    } ?: enumEntries<T>().random().also { variant ->
        LocalStorage.save(key, variant.name)
    }