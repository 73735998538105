package info.rezident.app.components.core

import kotlinx.browser.window

object LocalStorage {

    fun save(key: String, value: String) {
        window.localStorage.setItem(key, value)
    }

    fun get(key: String): String? {
        return window.localStorage.getItem(key)
    }

    fun remove(key: String) {
        window.localStorage.removeItem(key)
    }
}