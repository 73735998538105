package info.rezident.app.components.data.auth

import dev.gitlive.firebase.Firebase
import dev.gitlive.firebase.analytics.analytics
import dev.gitlive.firebase.auth.FirebaseAuth
import dev.gitlive.firebase.auth.FirebaseUser
import dev.gitlive.firebase.auth.GoogleAuthProvider
import dev.gitlive.firebase.auth.auth
import kotlinx.coroutines.flow.Flow

const val WEB_CLIENT_ID = "701270043234-sr2hjmu1hvcb464b1k15m8oj602hgpki.apps.googleusercontent.com"

object AuthRepository {
    private val auth: FirebaseAuth = Firebase.auth
    val firebaseUser: Flow<FirebaseUser?> = auth.authStateChanged

    suspend fun signInWithGoogle(idToken: String) {
        try {
            val credential = GoogleAuthProvider.credential(idToken = idToken, accessToken = null)
            auth.signInWithCredential(credential)

            Firebase.analytics.logEvent("user_log_in")
        } catch (e: Exception) {
            console.error("Error signing in with Google: $e")
        }
    }

    suspend fun logOut() {
        auth.signOut()
        Firebase.analytics.logEvent("user_log_out")
    }
}