package info.rezident.app.components.data

enum class HomePageVariant(val titleText: String, val subtitleText: String) {
    A("Descoperă-ți\nspecialitatea la ", "Află totul despre specialitățile care ți se potrivesc"),
    B("Descoperă-ți\nspecialitatea la ", "Află care specialități ți se potrivesc mai mult"),
    C("Descoperă\nspecialitățile la ", "Află totul despre specialitățile care ți se potrivesc"),
    D("Descoperă\nspecialitățile la ", "Află care specialități ți se potrivesc mai mult"),
    E("Află în 2 minute\nspecialitatea la ", "Descoperă totul despre specialitățile care ți se potrivesc"),
    F("Află în 2 minute\nspecialitatea la ", "Descoperă care specialități ți se potrivesc mai mult"),
    G("Află în câteva minute\nspecialitatea la ", "Descoperă totul despre specialitățile care ți se potrivesc"),
    H("Află în câteva minute\nspecialitatea la ", "Descoperă care specialități ți se potrivesc mai mult");

    companion object {
        const val KEY = "home_variant"
    }
}

enum class CTAVariant(val buttonText: String) {
    A("Descoperă acum"),
    B("Începe acum"),
    C("Începe explorarea"),
    D("Obține informații");

    companion object {
        const val KEY = "cta_variant"
    }
}

enum class HelloPageTitleVariant(val text: String) {
    A("Pentru a începe, adaugă o adresă de e-mail."),
    B("Hai să ne cunoaștem, lasă-ne un contact pentru a începe.");

    companion object {
        const val KEY = "hello_title_variant"
    }
}

enum class HelloPageSubtitleVariant(val text: String) {
    A("Promitem să nu te deranjăm cu mesaje"),
    B("E necesar ca să nu pierzi informațiile despre specialități");

    companion object {
        const val KEY = "hello_subtitle_variant"
    }
}

enum class QuizStep(val title: String, val description: String) {
    SPECIALISATION_TYPE("", ""),
    SKILLS("", ""),
    SUMMARY("", ""),
    AUTH("Hai sa ...", ""),
    MEMBERSHIP("", ""),
}