package info.rezident.app.components.sections

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.css.functions.clamp
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.forms.ButtonVars
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.CloseIcon
import com.varabyte.kobweb.silk.components.icons.HamburgerIcon
import com.varabyte.kobweb.silk.components.icons.MoonIcon
import com.varabyte.kobweb.silk.components.icons.SunIcon
import com.varabyte.kobweb.silk.components.layout.Surface
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.navigation.UncoloredLinkVariant
import com.varabyte.kobweb.silk.components.navigation.UndecoratedLinkVariant
import com.varabyte.kobweb.silk.components.overlay.Overlay
import com.varabyte.kobweb.silk.components.overlay.OverlayVars
import com.varabyte.kobweb.silk.style.animation.Keyframes
import com.varabyte.kobweb.silk.style.animation.toAnimation
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.breakpoint.displayUntil
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import dev.gitlive.firebase.Firebase
import dev.gitlive.firebase.analytics.analytics
import info.rezident.app.components.widgets.IconButton
import info.rezident.app.toSitePalette
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Text

@Composable
private fun NavLink(path: String, text: String) {
    Link(path, text, variant = UndecoratedLinkVariant.then(UncoloredLinkVariant))
}

@Composable
private fun MenuItems() {
//    NavLink("/about", "Despre Tine")
}

@Composable
private fun ColorModeButton() {
    var colorMode by ColorMode.currentState
    IconButton(onClick = {
        colorMode = colorMode.opposite
        Firebase.analytics.logEvent(
            "dark_mode_switch",
            mapOf("dark_mode_new_state" to colorMode.isDark)
        )
    }) {
        if (colorMode.isLight) MoonIcon() else SunIcon()
    }
//    Tooltip(ElementTarget.PreviousSibling, "Toggle color mode", placement = PopupPlacement.BottomRight)
}

@Composable
private fun HamburgerButton(onClick: () -> Unit) {
    IconButton(onClick) {
        HamburgerIcon()
    }
}

@Composable
private fun CloseButton(onClick: () -> Unit) {
    IconButton(onClick) {
        CloseIcon()
    }
}

val SideMenuSlideInAnim = Keyframes {
    from {
        Modifier.translateX(100.percent)
    }

    to {
        Modifier
    }
}

// Note: When the user closes the side menu, we don't immediately stop rendering it (at which point it would disappear
// abruptly). Instead, we start animating it out and only stop rendering it when the animation is complete.
enum class SideMenuState {
    CLOSED,
    OPEN,
    CLOSING;

    fun close() = when (this) {
        CLOSED -> CLOSED
        OPEN -> CLOSING
        CLOSING -> CLOSING
    }
}

@Composable
fun NavHeader(
    modifier: Modifier = Modifier,
    isAuthenticated: Boolean = false,
    name: String? = null,
    imageUrl: String? = null,
    onSignOut: () -> Unit = {}
) {
    Row(
        modifier.padding(left = 8.px).fillMaxWidth(),
        verticalAlignment = Alignment.Top
    ) {
        Link("/") {
            // Block display overrides inline display of the <img> tag, so it calculates centering better
            val colorMode = ColorMode.current
            Image(
                if (colorMode.isLight) {
                    "/logo-light.png"
                } else {
                    "/logo-dark.png"
                },
                "rezident.info Logo",
                Modifier
                    .height(6.cssRem)
                    .display(DisplayStyle.Block)
            )
        }

        Spacer()

//        Row(
//            Modifier.gap(1.cssRem).padding(8.px).displayIfAtLeast(Breakpoint.MD),
//            verticalAlignment = Alignment.CenterVertically
//        ) {
//            MenuItems()
//            ColorModeButton()
//        }

        Row(
            Modifier
                .fontSize(1.5.cssRem)
                .gap(1.cssRem)
                .padding(8.px)
                .displayUntil(Breakpoint.MD),
            verticalAlignment = Alignment.CenterVertically
        ) {
//            var menuState by remember { mutableStateOf(SideMenuState.CLOSED) }
//
//            ColorModeButton()
//            HamburgerButton(onClick = { menuState = SideMenuState.OPEN })
//
//            if (menuState != SideMenuState.CLOSED) {
//                SideMenu(
//                    menuState,
//                    close = { menuState = menuState.close() },
//                    onAnimationEnd = { if (menuState == SideMenuState.CLOSING) menuState = SideMenuState.CLOSED }
//                )
//            }
        }

        Box(Modifier.padding(8.px)) {
            UserArea(isAuthenticated, name, imageUrl, onSignOut)
        }
    }
}

@Composable
private fun SideMenu(menuState: SideMenuState, close: () -> Unit, onAnimationEnd: () -> Unit) {
    Overlay(
        Modifier
            .setVariable(OverlayVars.BackgroundColor, Colors.Transparent)
            .onClick { close() }
    ) {
        key(menuState) { // Force recompute animation parameters when close button is clicked
            Column(
                Modifier
                    .fillMaxHeight()
                    .width(clamp(8.cssRem, 33.percent, 10.cssRem))
                    .align(Alignment.CenterEnd)
                    // Close button will appear roughly over the hamburger button, so the user can close
                    // things without moving their finger / cursor much.
                    .padding(top = 1.cssRem, leftRight = 1.cssRem)
                    .gap(1.5.cssRem)
                    .backgroundColor(ColorMode.current.toSitePalette().nearBackground)
                    .animation(
                        SideMenuSlideInAnim.toAnimation(
                            duration = 200.ms,
                            timingFunction = if (menuState == SideMenuState.OPEN) AnimationTimingFunction.EaseOut else AnimationTimingFunction.EaseIn,
                            direction = if (menuState == SideMenuState.OPEN) AnimationDirection.Normal else AnimationDirection.Reverse,
                            fillMode = AnimationFillMode.Forwards
                        )
                    )
                    .borderRadius(topLeft = 2.cssRem)
                    .onClick { it.stopPropagation() }
                    .onAnimationEnd { onAnimationEnd() },
                horizontalAlignment = Alignment.End
            ) {
                CloseButton(onClick = { close() })
                Column(
                    Modifier.padding(right = 0.75.cssRem).gap(1.5.cssRem).fontSize(1.4.cssRem),
                    horizontalAlignment = Alignment.End
                ) {
                    MenuItems()
                }
            }
        }
    }
}

@Composable
private fun UserArea(isAuthenticated: Boolean, name: String?, imageUrl: String?, onLogOut: () -> Unit) {
    var isDropdownVisible by remember { mutableStateOf(false) }

    Column(horizontalAlignment = Alignment.End) {
        Row(
            Modifier
                .cursor(Cursor.Pointer)
                .onMouseEnter {
                    Modifier
                        .outline(2.px, LineStyle.Solid, Colors.Transparent)
                        .boxShadow(spreadRadius = 0.1875.cssRem, color = ButtonVars.BackgroundFocusColor.value())
                }
                .onClick { isDropdownVisible = !isDropdownVisible },
            verticalAlignment = Alignment.CenterVertically
        ) {
            ColorModeButton()

            if (isAuthenticated) {
                Div(Modifier.width(12.px).toAttrs())

                Text(
                    if (name != null) {
                        "Salut, $name"
                    } else {
                        "Profil"
                    }
                )

                Div(Modifier.width(8.px).toAttrs())

                Img(
                    src = imageUrl ?: "https://picsum.photos/50",
                    alt = name ?: "Imagine profil",
                    attrs = {
                        style {
                            width(48.px)
                            height(48.px)
                            borderRadius(50.percent)
                            borderStyle(LineStyle.Solid)
                            borderWidth(2.px)
                            borderColor(Color.black)
                            objectFit(ObjectFit.Cover)
                        }
                    }
                )
            }
        }

        if (isAuthenticated && isDropdownVisible) {
            Div(Modifier.height(4.px).toAttrs())
            DropdownMenu(
                onLogoutClick = {
                    onLogOut()
                }
            )
        }
    }
}

@Composable
private fun DropdownMenu(onLogoutClick: () -> Unit) {
    Surface(
        Modifier
            .backgroundColor(Color.white)
            .cursor(Cursor.Default)
            .padding(16.px)
            .onClick { onLogoutClick() },
        contentAlignment = Alignment.Center
    ) {
        Text("Ieșire")
    }
}