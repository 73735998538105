package info.rezident.app.components.core

import kotlinx.browser.document
import org.w3c.dom.HTMLScriptElement

internal fun loadAuthScript(scriptUrl: String, onLoaded: () -> Unit) {
    if (document.querySelector("script[src='$scriptUrl']") == null) {
        // Create and load the script
        (document.createElement("script") as? HTMLScriptElement)?.apply {
            src = scriptUrl
            async = true
            defer = true
            onload = {
                onLoaded()
            }

            document.head?.appendChild(this)
        }
    } else {
        // If script already exists, consider it loaded
        onLoaded()
    }
}

internal fun loadAdsScript() {
    (document.createElement("script") as? HTMLScriptElement)?.apply {
        src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4791318943765971"
        async = true
        crossOrigin = "anonymous"

        document.head?.appendChild(this)
    }
}