package info.rezident.app

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.varabyte.kobweb.compose.css.ScrollBehavior
import com.varabyte.kobweb.compose.ui.modifiers.minHeight
import com.varabyte.kobweb.compose.ui.modifiers.scrollBehavior
import com.varabyte.kobweb.core.App
import com.varabyte.kobweb.silk.SilkApp
import com.varabyte.kobweb.silk.components.layout.Surface
import com.varabyte.kobweb.silk.init.InitSilk
import com.varabyte.kobweb.silk.init.InitSilkContext
import com.varabyte.kobweb.silk.style.common.SmoothColorStyle
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import dev.gitlive.firebase.Firebase
import dev.gitlive.firebase.FirebaseOptions
import dev.gitlive.firebase.initialize
import kotlinx.browser.localStorage
import kotlinx.browser.window
import org.jetbrains.compose.web.css.vh

private const val COLOR_MODE_KEY = "app:colorMode"

@InitSilk
fun initColorMode(ctx: InitSilkContext) {
    ctx.config.initialColorMode = localStorage.getItem(COLOR_MODE_KEY)?.let { ColorMode.valueOf(it) } ?: ColorMode.DARK
}

@App
@Composable
fun AppEntry(content: @Composable () -> Unit) {
    Firebase.initialize(
        options = if (isLocalEnvironment()) {
            FirebaseOptions(
                apiKey = "AIzaSyApKqkg-SuNW2DbOd3TWghrFpt30HsUhJs",
                authDomain = "rezident-info-dev.firebaseapp.com",
                projectId = "rezident-info-dev",
                storageBucket = "rezident-info-dev.firebasestorage.app",
                gcmSenderId = "203818676574",
                applicationId = "1:203818676574:web:29534d9d45ce983cb2dfcf",
                gaTrackingId = "G-4VJKQMFR8C"
            )
        } else {
            FirebaseOptions(
                apiKey = "AIzaSyCE8wYZNRqVZfeakQ0f9wFPjHbeD_vV9m8",
                authDomain = "rezident-info.firebaseapp.com",
                projectId = "rezident-info",
                storageBucket = "rezident-info.firebasestorage.app",
                gcmSenderId = "701270043234",
                applicationId = "1:701270043234:web:852d50a5021cb637343f3b",
                gaTrackingId = "G-DYWPRPR0KQ"
            )
        }
    )

    SilkApp {
        val colorMode = ColorMode.current
        LaunchedEffect(colorMode) {
            localStorage.setItem(COLOR_MODE_KEY, colorMode.name)
        }

        Surface(
            SmoothColorStyle.toModifier()
                .minHeight(100.vh)
                .scrollBehavior(ScrollBehavior.Smooth)
        ) {
            content()
        }
    }
}

fun isLocalEnvironment() = window.location.hostname == "localhost" || window.location.hostname.startsWith("127.")