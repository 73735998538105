package info.rezident.app.pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.varabyte.kobweb.compose.css.FontSize
import com.varabyte.kobweb.compose.css.StyleVariable
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.forms.ButtonSize
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.base
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.breakpoint.displayIfAtLeast
import com.varabyte.kobweb.silk.style.toAttrs
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import com.varabyte.kobweb.silk.theme.colors.ColorSchemes
import dev.gitlive.firebase.Firebase
import dev.gitlive.firebase.analytics.analytics
import info.rezident.app.HeadlineTextStyle
import info.rezident.app.components.core.loadAdsScript
import info.rezident.app.components.data.CTAVariant
import info.rezident.app.components.data.HomePageVariant
import info.rezident.app.components.data.user.ABTestRepository
import info.rezident.app.components.sections.Footer
import info.rezident.app.components.sections.NavHeader
import info.rezident.app.toSitePalette
import kotlinx.browser.document
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.vh
import org.jetbrains.compose.web.dom.Div

// Container that has a tagline and grid on desktop, and just the tagline on mobile
val HeroContainerStyle = CssStyle {
    base { Modifier.fillMaxWidth().gap(2.cssRem) }
    Breakpoint.MD { Modifier.margin { top(20.vh) } }
}

// A demo grid that appears on the homepage because it looks good
val HomeGridStyle = CssStyle.base {
    Modifier
        .gap(0.5.cssRem)
        .width(70.cssRem)
        .height(18.cssRem)
}

private val GridCellColorVar by StyleVariable<Color>()
val HomeGridCellStyle = CssStyle.base {
    Modifier
        .backgroundColor(GridCellColorVar.value())
        .boxShadow(blurRadius = 0.6.cssRem, color = GridCellColorVar.value())
        .borderRadius(1.cssRem)
}

@Composable
private fun GridCell(color: Color, row: Int, column: Int, width: Int? = null, height: Int? = null) {
    Div(
        HomeGridCellStyle.toModifier()
            .setVariable(GridCellColorVar, color)
            .gridItem(row, column, width, height)
            .toAttrs()
    )
}

@Page
@Composable
fun HomePage() {
    LaunchedEffect(Unit) {
        document.title = "Specializarea potrivită ție 🩺"
        loadAdsScript()
    }

    Box(Modifier.fillMaxSize()) {
        Div(Modifier.fillMaxWidth().align(Alignment.TopCenter).toAttrs()) {
            NavHeader()
        }

        Column(
            Modifier.align(Alignment.Center),
            verticalArrangement = Arrangement.Center,
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            Row(verticalAlignment = Alignment.CenterVertically) {
                Div(Modifier.weight(1).toAttrs())

                val sitePalette = ColorMode.current.toSitePalette()
                Column(Modifier.padding(16.px)) {
                    val homeVariant = ABTestRepository.homeVariant
                    Div(HeadlineTextStyle.toAttrs()) {

                        SpanText(
                            homeVariant.titleText,
                            Modifier
                                .color(
                                    when (ColorMode.current) {
                                        ColorMode.LIGHT -> Colors.Black
                                        ColorMode.DARK -> Colors.White
                                    }
                                )
                        )
                        SpanText(
                            "Rezi",
                            Modifier
                                .color(sitePalette.brand.accent)
                                // Use a shadow so this light-colored word is more visible in light mode
                                .textShadow(0.px, 0.px, blurRadius = 0.5.cssRem, color = Colors.Gray)
                        )
                    }

                    SpanText(
                        homeVariant.subtitleText,
                        Modifier
                            .color(
                                when (ColorMode.current) {
                                    ColorMode.LIGHT -> Colors.Black
                                    ColorMode.DARK -> Colors.White.darkened(0.2f)
                                }
                            )
                    )

                    Box(Modifier.height(48.px))

                    val pageContext = rememberPageContext()
                    val analytics = Firebase.analytics
                    val ctaVariant = ABTestRepository.ctaVariant

                    Button(
                        onClick = {
                            pageContext.router.tryRoutingTo("/hello")
                            analytics.logEvent(
                                "landing_cta_click",
                                mapOf(
                                    HomePageVariant.KEY to homeVariant.name,
                                    CTAVariant.KEY to ctaVariant.name
                                )
                            )
                        },
                        Modifier.fontSize(FontSize.XXLarge).borderRadius(24.px),
                        size = ButtonSize.LG,
                        colorScheme = ColorSchemes.Orange,
                    ) {
                        SpanText(ctaVariant.buttonText)
                    }
                }

                Div(Modifier.weight(1).toAttrs())

                Image(
                    "/hero.webp",
                    "Viitori medici rezidenți",
                    Modifier
                        .weight(1)
                        .fillMaxWidth(50.percent)
                        .displayIfAtLeast(Breakpoint.MD)
                )
            }
        }

        Footer(Modifier.fillMaxWidth().align(Alignment.BottomCenter))
    }
}